import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import SideNavigation from 'components/SideNavigation';
import TopNavigation from 'components/TopNavigation';

const MainLayout = () => {
  const [pageTitle, setPageTitle] = useState<ReactNode>(null);
  const [actionButton, setActionButton] = useState<ReactNode>(null);

  return (
    <div className="flex h-screen w-screen">
      <SideNavigation />
      <div className="w-[calc(100vw-81px)] flex-1">
        <TopNavigation pageTitle={pageTitle} actionButton={actionButton} />
        <div className="h-[calc(100vh-66px)]">
          <Outlet context={{ setPageTitle, setActionButton }} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
