const TOKEN_KEY = 'token';

export const saveToken = (token: string) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};
export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};
export const getToken = () => {
  // Check if window.localStorage is available
  if (window.localStorage) {
    return window.localStorage.getItem(TOKEN_KEY);
  } else {
    // Handle the case when localStorage is not available
    return null;
  }
};
