import { BASE_URL, SortRequestProps, post, useFetch } from 'utils/sdk';

export interface ContractsDashboardListResponse {
  id: number;
  farm_name: string;
  farm_location: string;
  land_size: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  lead_outgrower_first_name: string | null;
  lead_outgrower_last_name: string | null;
  crop: string;
  expected_harvest_amount: number;
}

export interface ContractListResponse {
  id: number;
  outgrower_first_name: string;
  outgrower_last_name: string;
  crop: string;
  execution_start_date: string;
  end_date: string;
  expected_harvest_amount: number;
  actual_harvest_amount: number;
  harvest_date: string;
  expected_farmgate_price: number;
}

export interface FertilizerSummaryProps {
  name: string;
  amount: number;
  unit: string;
  value: number;
}

export interface ChemicalSummaryProps {
  name: string;
  amount: number;
  unit: string;
  value: number;
}

export interface ContractSummaryPreviewResponse {
  plant_material_amount: number;
  plant_material_unit: string;
  plant_material_value: number;
  fertilizers: Array<FertilizerSummaryProps>;
  chemicals: Array<ChemicalSummaryProps>;
  yield_per_ha: number;
  total_yield: number;
  price_per_ton: number;
  total_amount: number;
  revenue: number;
}

export interface ContractSummaryPreviewProps {
  outgrower_id: number;
  crop_id: number;
  crop_variety_id: number;
  farm_id: number;
  land_size: number;
}

export const useContractsDashboardList = () =>
  useFetch<Array<ContractsDashboardListResponse>>(
    `/api/contracts/dashboard/list/`
  );

export interface ContractsListFiltersProps {
  status: string;
  search?: string;
}

export const useContractsList = ({
  filters,
  sort
}: {
  filters: ContractsListFiltersProps;
  sort: SortRequestProps;
}) =>
  useFetch<Array<ContractListResponse>>(`/api/contracts/`, {
    ...filters,
    ...sort
  });

export const contractSummaryPreview = (data: ContractSummaryPreviewProps) =>
  post<ContractSummaryPreviewResponse>(
    `${BASE_URL}/api/contracts/summary/preview/`,
    data
  );
