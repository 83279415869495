import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import { ContractsListFiltersProps } from 'entities/Contracts/sdk';

import ClickableChip from 'components/ClickableChip';

interface ActiveClosedContractsChipListProps {
  filters: ContractsListFiltersProps;
  onFilter: (filters: ContractsListFiltersProps) => void;
}

const ActiveClosedContractsChipList = ({
  filters,
  onFilter
}: ActiveClosedContractsChipListProps) => {
  const handleChipClick = (status: string | number) => {
    onFilter({ status: status as string });
  };

  return (
    <div className="flex flex-row gap-1">
      <ClickableChip
        chipKey={CONTRACT_STATUSES.ACTIVE}
        label="Active Contracts"
        classes={{
          root: 'border border-solid border-wet-green px-4 py-[7px] m-0 text-sm'
        }}
        isClicked={filters.status === CONTRACT_STATUSES.ACTIVE}
        onClick={handleChipClick}
      />
      <ClickableChip
        chipKey={CONTRACT_STATUSES.CLOSED}
        label="Closed Contracts"
        classes={{
          root: 'border border-solid border-wet-green px-4 py-[7px] m-0 text-sm'
        }}
        isClicked={filters.status === CONTRACT_STATUSES.CLOSED}
        onClick={handleChipClick}
      />
    </div>
  );
};

export default ActiveClosedContractsChipList;
