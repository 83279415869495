import { useFetch } from 'utils/sdk';

export interface DashboardStatisticsResponse {
  active_contracts: number;
  received_tasks: number;
  understood_tasks: number;
  expected_harvest: number;
  financial_exposure: number;
  expected_farmgate_price: number;
}

export const useDashboardStatistics = () =>
  useFetch<DashboardStatisticsResponse>(`/api/companies/dashboard/statistics/`);
