import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from 'assets';

import DasboardListTable from 'entities/Contracts/components/DashboardListTable';
import { useContractsDashboardList } from 'entities/Contracts/sdk';
import CropChipList from 'entities/Crops/components/CropChipList';
import { useCropChoicesList } from 'entities/Crops/sdk';
import DashboardStatistics from 'entities/Dashboard/components/DashboardStatistics';
import { useDashboardStatistics } from 'entities/Dashboard/sdk';

import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { URLS } from 'config/urls';

const Dashboard = () => {
  const { setPageTitle, setActionButton } = useLayout();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle(<PageTitle text="Dashboard"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  useEffect(() => {
    setActionButton(
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        color="wetGreen"
        onClick={() => navigate(URLS.OUTGROWER_CREATE)}
      >
        Add outgrower
      </Button>
    );

    return () => setActionButton(null);
  }, [setActionButton, navigate]);

  const { data: contracts, isLoading: contractsLoading } =
    useContractsDashboardList();

  const { data: crops, isLoading: cropsLoading } = useCropChoicesList();
  const { data: statistics, isLoading: statisticsLoading } =
    useDashboardStatistics();

  return (
    <div className="flex h-full flex-col gap-3 bg-[#F8F8F8] px-[26px] py-5">
      {(cropsLoading || statisticsLoading || contractsLoading) && (
        <ContentLoading />
      )}
      {!cropsLoading && crops && <CropChipList crops={crops} />}
      {!statisticsLoading && statistics && (
        <DashboardStatistics statistics={statistics} />
      )}
      {contractsLoading && (
        <div className="flex h-[500px] items-center justify-center">
          <CircularProgress />
        </div>
      )}
      {!contractsLoading && contracts && (
        <DasboardListTable contracts={contracts} />
      )}
    </div>
  );
};

export default Dashboard;
