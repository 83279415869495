import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue
} from 'react-hook-form';

import { FormProps } from 'entities/Contracts/components/ContractCreateForm';
import CropChipList from 'entities/Crops/components/CropChipList';
import { CropChoicesListResponse } from 'entities/Crops/sdk';

import FormHelperText from 'components/FormHelperText';

interface ICropChipList {
  crops: Array<CropChoicesListResponse>;
  control: Control<FormProps>;
  setValue: UseFormSetValue<FormProps>;
  errors: FieldErrors<FormProps>;
  className?: string;
  onCropClick?: (cropId: number | null) => void;
}

const ConractCropSelect = ({
  crops,
  className,
  control,
  setValue,
  errors,
  onCropClick
}: ICropChipList) => {
  return (
    <Controller
      name="crop"
      control={control}
      rules={{
        required: 'You need to select a crop'
      }}
      render={() => (
        <div>
          <CropChipList
            crops={crops}
            setValue={setValue}
            onCropClick={onCropClick}
            className={className}
          />
          {!!errors.crop && (
            <FormHelperText error={true}>{errors.crop?.message}</FormHelperText>
          )}
        </div>
      )}
    ></Controller>
  );
};

export default ConractCropSelect;
