import { useEffect, useState } from 'react';

import ActiveClosedContractsChipList from 'entities/Contracts/components/ActiveClosedContractsChipList';
import ContractsListFilters from 'entities/Contracts/components/ContractsListFilters';
import ContractsListTable from 'entities/Contracts/components/ContractsListTable';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import {
  ContractsListFiltersProps,
  useContractsList
} from 'entities/Contracts/sdk';

import Card from 'components/Card';
import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

import { ListSortProps, buildSort } from 'utils/sdk';

const ContractsList = () => {
  const { setPageTitle } = useLayout();

  const [filters, setFilters] = useState<ContractsListFiltersProps>({
    status: CONTRACT_STATUSES.ACTIVE
  });
  const [sort, setSort] = useState<ListSortProps>({
    sort: 'id',
    direction: 'asc'
  });

  useEffect(() => {
    setPageTitle(<PageTitle text="Contracts"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: contracts, isLoading: contractsLoading } = useContractsList({
    filters,
    sort: buildSort(sort)
  });

  return (
    <div className="flex h-full flex-col px-[26px] py-5">
      <Card
        classes={{
          root: 'flex flex-col h-full  gap-5 p-5 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
        }}
      >
        <div className="flex items-center justify-between">
          <ActiveClosedContractsChipList
            filters={filters}
            onFilter={setFilters}
          />
          <ContractsListFilters filters={filters} onFilter={setFilters} />
        </div>
        {contractsLoading && <ContentLoading />}
        {!contractsLoading && contracts && (
          <ContractsListTable
            contracts={contracts}
            filters={filters}
            onSort={setSort}
            sort={sort}
          />
        )}
      </Card>
    </div>
  );
};

export default ContractsList;
