import {
  DocumentInGreenCircleIcon,
  PersonInGeeenCircleIcon,
  SpaInGreenCircleIcon
} from 'assets';
import { flatMap, sumBy } from 'lodash';

import { OutgrowerListResponse } from 'entities/Outgrower/sdk';

import Skeleton from 'components/Skeleton';
import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';

interface OutgrowerListTableProps {
  outgrowers?: Array<OutgrowerListResponse>;
  outgrowersLoading: boolean;
}

interface StatisticsValueProps {
  value: number;
}

const StatisticsValue = ({ value }: StatisticsValueProps) => {
  return (
    <div className="flex items-end items-baseline gap-1">
      <Text
        classes={{
          root: 'font-semibold text-[34px] text-wet-green  leading-[34px] tracking-[0.15px]'
        }}
      >
        {value}
      </Text>
    </div>
  );
};

const OutgrowerListStatistics = ({
  outgrowers,
  outgrowersLoading
}: OutgrowerListTableProps) => {
  const activeFarms = flatMap(outgrowers, 'farms').length;

  const activeContracts = sumBy(outgrowers, 'active_contracts');
  return (
    <div className="flex gap-3">
      <StatisticsCard
        icon={<PersonInGeeenCircleIcon />}
        title="All Outgrowers"
        value={
          outgrowers && !outgrowersLoading ? (
            <StatisticsValue value={outgrowers.length} />
          ) : (
            <Skeleton width="34" height="34" />
          )
        }
      />
      <StatisticsCard
        icon={<SpaInGreenCircleIcon />}
        title="All Farms"
        value={
          outgrowers && !outgrowersLoading ? (
            <StatisticsValue value={activeFarms} />
          ) : (
            <Skeleton />
          )
        }
      />
      <StatisticsCard
        icon={<DocumentInGreenCircleIcon />}
        title="Active Contracts"
        value={
          outgrowers && !outgrowersLoading ? (
            <StatisticsValue value={activeContracts} />
          ) : (
            <Skeleton />
          )
        }
      />
    </div>
  );
};
export default OutgrowerListStatistics;
