import { generatePath, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Text from 'components/Text';

import { URLS } from 'config/urls';

interface ContractCreatedDialogProps {
  onClose: () => void;
}

const ContractCreatedDialog = ({ onClose }: ContractCreatedDialogProps) => {
  const navigate = useNavigate();
  return (
    <Dialog onClose={onClose} title="Contract added">
      <div className="py-8">
        <Text
          classes={{
            root: 'tracking-[0.15px] text-wet-green leading-[30px] font-medium text-xl pb-5'
          }}
        >
          You’ve successfully added new Contract.
        </Text>
      </div>

      <div className="flex justify-center gap-3">
        <Button
          variant="outlined"
          className="font-medium"
          color="wetGreen"
          onClick={() => navigate(URLS.CONTRACTS)}
        >
          View all contracts
        </Button>
        <Button
          variant="contained"
          className="font-medium"
          color="wetGreen"
          onClick={() =>
            navigate(generatePath(URLS.OUTGROWER_PROFILE, { id: '2' }))
          }
        >
          View Profile
        </Button>
      </div>
    </Dialog>
  );
};

export default ContractCreatedDialog;
