import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { AssignmentIcon, LogoutIcon, PeopleIcon, WhiteLogoIcon } from 'assets';
import { isNull } from 'lodash';
import { setSentryUser } from 'sentry';

import { logout } from 'entities/Auth/sdk';

import IconButton from 'components/IconButton';
import BackButton from 'components/SideNavigation/BackButton';
import NavigationTab from 'components/SideNavigation/NavigationTab';

import { removeToken } from 'utils/storage';

import { URLS } from 'config/urls';

const SideNavigation = () => {
  const [open, setOpened] = useState(false);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // The navigation is opened only on certain pages.
    // The navigation cannot be opened/ closed from the user, following the current design.
    if (
      [
        URLS.OUTGROWER_PROFILE,
        URLS.OUTGROWER_CONTRACTS,
        URLS.OUTGROWER_TASKS,
        URLS.OUTGROWER_TASKS_GALLERY
      ].some((url) => !isNull(matchPath(url, location.pathname)))
    ) {
      setOpened(true);
    }

    if (
      [URLS.OUTGROWERS, URLS.DASHBOARD, URLS.CONTRACTS].some(
        (url) => !isNull(matchPath(url, location.pathname))
      )
    ) {
      setOpened(false);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    return logout().then(() => {
      removeToken();
      setSentryUser({ email: '' });
      navigate(URLS.LOGIN);
    });
  };

  return (
    <>
      <div
        className={`flex h-full w-[81px] flex-col items-center gap-[13px] bg-wet-green px-2 py-5`}
      >
        <Link to={URLS.DASHBOARD} className="flex">
          <WhiteLogoIcon />
        </Link>
        <div className="flex flex-1 flex-col py-[15px]">
          <div className="flex flex-1 flex-col items-center">
            <NavigationTab to={URLS.OUTGROWERS} className="px-5 py-[15px]">
              <PeopleIcon />
            </NavigationTab>
            <NavigationTab to={URLS.CONTRACTS} className="px-5 py-[15px]">
              <AssignmentIcon />
            </NavigationTab>
          </div>
          <div className="px-3 leading-[0]">
            <IconButton
              onClick={handleLogout}
              aria-label="logout"
              classes={{ root: 'py-0' }}
            >
              <LogoutIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {open && (
        <div className="flex w-[107px] flex-col items-start gap-2 border-0 border-l border-solid border-[#b3cbbd]/50 bg-wet-green px-2 py-[30px]">
          <BackButton />

          <NavigationTab to={`/outgrowers/${params.id}/profile`}>
            Profile
          </NavigationTab>
          <NavigationTab to={`/outgrowers/${params.id}/contracts`}>
            Contracts
          </NavigationTab>
          <NavigationTab to={`/outgrowers/${params.id}/tasks`}>
            Tasks
          </NavigationTab>
        </div>
      )}
    </>
  );
};

export default SideNavigation;
