import { twMerge } from 'tailwind-merge';

import { TableCell } from 'components/Table';

interface TableHeaderCellProps {
  content: string | number | JSX.Element;
  className?: string;
}

const TableHeaderCell = ({ content, className }: TableHeaderCellProps) => (
  <TableCell
    component="th"
    key={content as string}
    align="center"
    classes={{
      root: twMerge(
        'px-[17px] py-3 text-base font-semibold	leading-6	tracking-[0.15px] h-[82px] text-wet-green',
        className
      )
    }}
    sx={{
      borderRight: '1px solid #c5c5c54d',
      borderLeft: '1px solid #c5c5c54d',
      borderBottom: '1px solid #c5c5c54d'
      // borderTop: '1px solid #c5c5c54d' // If we add a border top to the header cell, there is a bug of mui displaying the table content behind the cell
    }}
  >
    {content}
  </TableCell>
);

export default TableHeaderCell;
