import React from 'react';

import { DollarIcon, InformationIcon, ShoppingBasketIcon } from 'assets';

import { ContractSummaryPreviewResponse } from 'entities/Contracts/sdk';

import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

interface PreviewLabelProps {
  children: React.ReactNode;
}

const PreviewLabel = ({ children }: PreviewLabelProps) => {
  return (
    <Text
      color="#9E9E9E"
      className="min-w-[180px] pb-1 text-sm font-normal leading-[21px] tracking-[0.15px]"
    >
      {children}
    </Text>
  );
};

interface PreviewValueProps {
  children: React.ReactNode;
}
const PreviewValue = ({ children }: PreviewValueProps) => {
  return (
    <Text
      color="wet-green"
      className="min-w-[180px] text-base font-normal tracking-[0.15px]"
    >
      {children}
    </Text>
  );
};

interface PreviewHeaderProps {
  children?: React.ReactNode;
}

const PreviewHeader = ({ children }: PreviewHeaderProps) => {
  return (
    <div className="min-w-[180px]">
      {children && (
        <Text
          color="wet-green"
          className="text-base font-semibold leading-[19.2px]"
        >
          {children}
        </Text>
      )}
    </div>
  );
};

interface ContractPreviewProps {
  summary: ContractSummaryPreviewResponse;
}

const ContractPreview = ({ summary }: ContractPreviewProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <ShoppingBasketIcon />
            <PreviewHeader>Input</PreviewHeader>
          </div>
          <div className="flex border-0 border-b border-solid border-b-[#D4DAE1] pb-4">
            <PreviewHeader>Plant Material</PreviewHeader>
            <div>
              <PreviewLabel>Amount</PreviewLabel>
              <PreviewValue>
                {summary.plant_material_amount} {summary.plant_material_unit}
              </PreviewValue>
            </div>
            <div>
              <PreviewLabel>Value</PreviewLabel>
              <PreviewValue>{summary.plant_material_value} USD</PreviewValue>
            </div>
          </div>
          <div>
            <div className="flex">
              <PreviewHeader>Fertilizer</PreviewHeader>
              <PreviewLabel>Name</PreviewLabel>
              <PreviewLabel>Amount</PreviewLabel>
              <PreviewLabel>Value</PreviewLabel>
            </div>
            <div className="flex flex-col gap-2 border-0 border-b border-solid border-b-[#D4DAE1] pb-4">
              {summary.fertilizers.map((fertilizer) => (
                <div className="flex">
                  <PreviewHeader></PreviewHeader>
                  <PreviewValue>{fertilizer.name}</PreviewValue>
                  <PreviewValue>
                    {fertilizer.amount} {fertilizer.unit}
                  </PreviewValue>
                  <PreviewValue>{fertilizer.value} USD</PreviewValue>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="flex">
              <PreviewHeader>Chemicals</PreviewHeader>
              <PreviewLabel>Name</PreviewLabel>
              <PreviewLabel>Amount</PreviewLabel>
              <PreviewLabel>Value</PreviewLabel>
            </div>
            <div className="flex flex-col gap-2">
              {summary.chemicals.map((chemical) => (
                <div className="flex">
                  <PreviewHeader></PreviewHeader>
                  <PreviewValue>{chemical.name}</PreviewValue>
                  <PreviewValue>
                    {chemical.amount} {chemical.unit}
                  </PreviewValue>
                  <PreviewValue>{chemical.value} USD</PreviewValue>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <DollarIcon />
            <PreviewHeader>Output</PreviewHeader>
          </div>
          <div className="flex border-0 border-b border-solid border-b-[#D4DAE1] pb-4">
            <PreviewHeader>Expected harvest</PreviewHeader>
            <div>
              <PreviewLabel>Yield per ha</PreviewLabel>
              <PreviewValue>{summary.yield_per_ha} t</PreviewValue>
            </div>
            <div>
              <PreviewLabel>Total yield</PreviewLabel>
              <PreviewValue>{summary.total_yield} t</PreviewValue>
            </div>
          </div>
          <div className="flex border-0 border-b border-solid border-b-[#D4DAE1] pb-4">
            <PreviewHeader>Purchase price</PreviewHeader>
            <div>
              <PreviewLabel>Price per ton</PreviewLabel>
              <PreviewValue>{summary.price_per_ton} USD</PreviewValue>
            </div>
            <div>
              <PreviewLabel>Total amount</PreviewLabel>
              <PreviewValue>{summary.total_amount} USD</PreviewValue>
            </div>
          </div>
          <div className="flex">
            <PreviewHeader>
              <div className="flex items-center gap-2">
                <div>Expected payout</div>
                <Tooltip title="Purchase price minus input cost">
                  <InformationIcon />
                </Tooltip>
              </div>
            </PreviewHeader>
            <div>
              <PreviewLabel>Revenue</PreviewLabel>
              <PreviewValue>{summary.revenue} USD</PreviewValue>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractPreview;
