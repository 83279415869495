import { SubjectInGreenIcon } from 'assets';

import { OutgrowerDetailResponse } from 'entities/Outgrower/sdk';

import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';

interface OutgrowerProfileStatisticsProps {
  outgrower: OutgrowerDetailResponse;
}

interface OutgrowerProfileStatisticsValueProps {
  value: number;
}

const OutgrowerProfileStatisticsValue = ({
  value
}: OutgrowerProfileStatisticsValueProps) => {
  return (
    <Text
      classes={{
        root: 'font-semibold text-[34px] text-wet-green leading-[34px] tracking-[0.15px]'
      }}
    >
      {value}
    </Text>
  );
};

const OutgrowerProfileStatistics = ({
  outgrower
}: OutgrowerProfileStatisticsProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-3">
      <StatisticsCard
        icon={<SubjectInGreenIcon />}
        title="Active Farms"
        value={
          <OutgrowerProfileStatisticsValue value={outgrower.farms.length} />
        }
      />
      <StatisticsCard
        icon={<SubjectInGreenIcon />}
        title="Active Contracts"
        value={
          <OutgrowerProfileStatisticsValue value={outgrower.active_contracts} />
        }
      />
      <StatisticsCard
        icon={<SubjectInGreenIcon />}
        title="Closed Contracts"
        value={
          <OutgrowerProfileStatisticsValue value={outgrower.closed_contracts} />
        }
      />
    </div>
  );
};

export default OutgrowerProfileStatistics;
