import { ContractsDashboardListResponse } from 'entities/Contracts/sdk';

import Card from 'components/Card';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

interface DashboardListTableProps {
  contracts: Array<ContractsDashboardListResponse>;
}

const DashboardListTable = ({ contracts }: DashboardListTableProps) => {
  return (
    <Card
      classes={{
        root: 'flex flex-col p-4 rounded-xl border border-solid border-[#d4dae1] shadow-sm shadow-[rgba(16, 24, 40, 0.05)] bg-white'
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableHeaderCell content="Contract ID" className="w-[107px]" />
              <TableHeaderCell content="Farmer" />
              <TableHeaderCell content="Farm" />
              <TableHeaderCell content="Lead Farmer" />
              <TableHeaderCell content="Location" />
              <TableHeaderCell content="Size" />
              <TableHeaderCell content="Crop" />
              <TableHeaderCell content="Growth Stage" className="w-[97px]" />
              <TableHeaderCell content="Exposure" />
              <TableHeaderCell content="Expected Harvest" />
              <TableHeaderCell content="Weekly Task" className="w-[130px]" />
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((contract) => (
              <TableRow
                key={contract.id}
                classes={{
                  root: 'odd:bg-white even:bg-[#b3cbbd]/10'
                }}
              >
                <TableBodyCell content={contract.id} className="w-[107px]" />
                <TableBodyCell
                  content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                  className="font-medium"
                />
                <TableBodyCell
                  content={contract.farm_name}
                  className="font-medium"
                />
                <TableBodyCell
                  content={`${contract.lead_outgrower_first_name || ''} ${contract.lead_outgrower_last_name || ''}`}
                  className="font-medium"
                />
                <TableBodyCell content={contract.farm_location} />
                <TableBodyCell content={contract.land_size} />
                <TableBodyCell content={contract.crop} />
                <TableBodyCell content={'N/A'} className="w-[97px]" />
                <TableBodyCell content={'N/A'} />
                <TableBodyCell
                  content={`${contract.expected_harvest_amount} t`}
                />
                <TableBodyCell content={'N/A'} className="w-[130px]" />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DashboardListTable;
