import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContractForm from 'entities/Contracts/components/ContractCreateForm/ContractForm';
import ContractPreview from 'entities/Contracts/components/ContractCreateForm/ContractPreview';
import {
  ContractSummaryPreviewProps,
  ContractSummaryPreviewResponse,
  contractSummaryPreview
} from 'entities/Contracts/sdk';
import { CropChoicesListResponse } from 'entities/Crops/sdk';
import OutgrowerFarmSummary from 'entities/Outgrower/components/OutgrowerFarmSummary';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import Button from 'components/Button';

import { useForm } from 'utils/forms';

import { URLS } from 'config/urls';

export interface FormProps {
  crop: number;
  crop_variety: number;
  contract_land_size: number;
  signature_date: string;
  execution_date: string;
  end_date: string;
}

const defaultValues = {};

interface ContractCreateFormProps {
  onSave: (data: any) => Promise<any>;
  crops: Array<CropChoicesListResponse>;
  outgrowerId: number;
  outgrowerFarms: Array<OutgrowerFarmResponse>;
}

const ContractCreateForm = ({
  onSave,
  crops,
  outgrowerId,
  outgrowerFarms
}: ContractCreateFormProps) => {
  const navigate = useNavigate();

  const {
    control,
    getValues,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({ defaultValues: defaultValues });

  const farm: OutgrowerFarmResponse = outgrowerFarms[0]; // An outgrower can have only one farm;

  const [contractDataFilledIn, setContractDataFilledIn] = useState(false);
  const [summary, setSummary] = useState<ContractSummaryPreviewResponse>();

  const onSubmit = (data: FormProps) => {
    const submitData = data;

    onSave(submitData).catch(({ setFormErrors }) => setFormErrors(setError));
  };

  const handleShowPreview = useCallback(async () => {
    const cropId = getValues('crop');
    const cropVarietyId = getValues('crop_variety');
    const landSize = getValues('contract_land_size');
    const farmId = farm.id;
    const contractSummary: ContractSummaryPreviewProps = {
      outgrower_id: outgrowerId,
      crop_id: cropId,
      crop_variety_id: cropVarietyId,
      farm_id: farmId,
      land_size: landSize
    };
    contractSummaryPreview(contractSummary).then(
      (data: ContractSummaryPreviewResponse) => {
        setSummary(data);
        setContractDataFilledIn(true);
      }
    );
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
      <div className="flex-1 px-[26px] pt-5">
        <div className="flex gap-3">
          {!contractDataFilledIn && (
            <ContractForm
              crops={crops}
              errors={errors}
              control={control}
              setValue={setValue}
              farm={farm}
            />
          )}
          {contractDataFilledIn && summary && (
            <ContractPreview summary={summary} />
          )}
          <OutgrowerFarmSummary farm={farm} />
        </div>
      </div>
      <div className="flex h-[76px] items-end justify-end gap-4 p-4 shadow-[6px_0_4px_-1px_#D9D9D9]">
        {!contractDataFilledIn && (
          <Button
            variant="outlined"
            color="wetGreen"
            className="w-[178px]"
            onClick={() => navigate(URLS.DASHBOARD)}
          >
            Cancel
          </Button>
        )}

        {!contractDataFilledIn && (
          <Button
            className="w-[178px]"
            variant="contained"
            color="wetGreen"
            onClick={() => handleSubmit(handleShowPreview)()}
          >
            Next
          </Button>
        )}

        {contractDataFilledIn && (
          <Button
            variant="outlined"
            color="wetGreen"
            className="w-[178px]"
            onClick={() => setContractDataFilledIn(false)}
          >
            Back
          </Button>
        )}

        {contractDataFilledIn && (
          <Button
            className="w-[178px]"
            type="submit"
            variant="contained"
            color="wetGreen"
          >
            Create Contract
          </Button>
        )}
      </div>
    </form>
  );
};

export default ContractCreateForm;
