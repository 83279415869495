import clsx from 'clsx';

import Chip, { ChipProps } from 'components/Chip';

type ChipKeyProps = string | number;

interface ClickableChipProps extends ChipProps {
  chipKey: ChipKeyProps;
  isClicked: boolean;
  onClick: (chipKey: ChipKeyProps) => void;
}

const ClickableChip: React.FunctionComponent<ClickableChipProps> = ({
  chipKey,
  label,
  icon,
  classes,
  isClicked,
  onClick
}) => {
  const defaultClasses = {
    root: `gap-2 hover:cursor-pointer ${
      isClicked
        ? 'text-white bg-wet-green hover:bg-wet-green'
        : 'text-wet-green bg-white hover:bg-white'
    }`
  };
  const handleClick = () => {
    onClick(chipKey);
  };

  return (
    <Chip
      label={label}
      icon={icon}
      classes={{
        root: clsx(defaultClasses.root, classes?.root)
      }}
      onClick={handleClick}
    />
  );
};

export default ClickableChip;
