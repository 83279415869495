import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

export type NavigationTabProps = {
  children: React.ReactNode;
  className?: string;
  to: string;
};

const NavigationTab = ({ children, to, className }: NavigationTabProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => {
        return twMerge(
          'flex w-full items-center justify-start gap-2 px-3 py-2 no-underline',
          isActive && 'rounded-md bg-[#b3cbbd]/50',
          className
        );
      }}
    >
      <div className="flex items-center justify-start gap-1">
        <div className="flex items-center text-sm font-medium leading-normal text-white">
          {children}
        </div>
      </div>
    </NavLink>
  );
};

export default NavigationTab;
