import Button from 'components/Button';
import Error from 'components/Error';
import FormTextField from 'components/Form/TextField';

import { useForm } from 'utils/forms';

interface FormProps {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSave: (data: FormProps) => Promise<any>;
}

const LoginForm = ({ onSave }: LoginFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm<FormProps>({
    defaultValues: { email: '', password: '' }
  });

  const onSubmit = (data: FormProps) => {
    onSave(data).catch(
      (errors) => errors.setFormErrors && errors.setFormErrors(setError)
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
      <div className="flex w-[300px] flex-col gap-6">
        {errors.root?.serverError.message && (
          <Error>{errors.root?.serverError.message}</Error>
        )}

        <FormTextField
          name="email"
          control={control}
          fieldProps={{
            label: 'Email',
            placeholder: 'Enter Email',
            error: !!errors.email,
            helperText: errors.email?.message
          }}
        />

        <FormTextField
          name="password"
          control={control}
          fieldProps={{
            label: 'Password',
            type: 'password',
            placeholder: 'Enter Password',
            error: !!errors.password,
            helperText: errors.password?.message
          }}
        />
      </div>
      <Button type="submit" variant="contained" color="wetGreen">
        Login
      </Button>
    </form>
  );
};
export default LoginForm;
