import { OutgrowerListFilterProps as OutgrowerFilterProps } from 'entities/Outgrower/sdk';

import { SearchInput } from 'components/Filters';

interface OutgrowerListFiltersProps {
  onFilter: (filters: OutgrowerFilterProps) => void;
}

const OutgrowerListFilters = ({ onFilter }: OutgrowerListFiltersProps) => {
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilter({ search: value });
  };

  return (
    <div className="flex justify-end">
      <SearchInput placeholder="Search your outgrowers" onSearch={onSearch} />
    </div>
  );
};

export default OutgrowerListFilters;
