import { default as MuiDialog } from '@mui/material/Dialog';
import { default as MuiDialogContent } from '@mui/material/DialogContent';
import { default as MuiDialogTitle } from '@mui/material/DialogTitle';
import { CloseIcon } from 'assets';

import IconButton from 'components/IconButton';

interface DialogProps {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

const Dialog = ({ title, children, onClose, ...props }: DialogProps) => (
  <MuiDialog open {...props}>
    <MuiDialogTitle
      classes={{
        root: 'pt-4 pb-0 px-[34px] text-center font-semibold leading-9 tracking-[0.15px] text-wet-green'
      }}
    >
      {title}
    </MuiDialogTitle>
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={() => ({
        position: 'absolute',
        right: 15.8,
        top: 22,
        padding: 0
      })}
    >
      <CloseIcon />
    </IconButton>
    <MuiDialogContent
      classes={{
        root: 'px-[34px] pb-4 pt-0'
      }}
    >
      {children}
    </MuiDialogContent>
  </MuiDialog>
);

export default Dialog;
