import { ChevronLeft, ChevronRight } from 'assets';

import DatePicker, {
  DatePickerProps,
  PickersCalendarHeaderProps
} from 'components/DatePicker';
import IconButton from 'components/IconButton';
import Text from 'components/Text';

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'utils/forms';

interface FormDateFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  rules?: RegisterOptions<T>;
  fieldProps: {
    placeholder?: string;
    error?: boolean;
    helperText?: string;
  };
  datePickerProps: DatePickerProps;
}

const CustomCalendarHeader = (props: PickersCalendarHeaderProps) => {
  const { currentMonth, onMonthChange } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, 'month'), 'left');
  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, 'month'), 'right');

  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex gap-1">
        <IconButton onClick={selectPreviousMonth} title="Previous month">
          <ChevronLeft />
        </IconButton>
      </div>
      <Text
        color="wet-green"
        className="font-normal leading-6 tracking-[0.15px]"
      >
        {currentMonth.format('MMMM YYYY')}
      </Text>
      <div className="flex gap-1">
        <IconButton onClick={selectNextMonth} title="Next month">
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};

const FormDateField = <T extends FieldValues>({
  name,
  control,
  rules,
  fieldProps,
  datePickerProps
}: FormDateFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <DatePicker
          format="DD.MM.YYYY"
          {...datePickerProps}
          {...field}
          slotProps={{
            layout: {
              sx: {
                '.MuiDateCalendar-root': {
                  width: '244px'
                }
              }
            },
            desktopPaper: {
              classes: {
                root: 'rounded-xl border-solid border-[#D4DAE1] border shadow-0'
              }
            },
            textField: {
              classes: {
                root: 'w-[244px]'
              },
              placeholder: fieldProps.placeholder,
              error: fieldProps.error,
              helperText: fieldProps.helperText
            },
            day: {
              classes: {
                root: 'w-[32px] h-[32px] flex items-center justify-center text-wet-green font-normal tracking-[0.15px] m-0'
              },
              sx: {
                '&.MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#062D29'
                }
              }
            },
            toolbar: { sx: { color: '#062D29' } },
            actionBar: {
              sx: {
                '.MuiButton-root': {
                  color: '#062D29',
                  fontWeight: 500
                }
              }
            }
          }}
          slots={{
            calendarHeader: CustomCalendarHeader
          }}
        />
      )}
    />
  );
};

export default FormDateField;
