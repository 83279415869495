import { createTheme } from '@mui/material/styles';

import paletteColors from './colors.ts';

const theme = createTheme({
  palette: paletteColors,
  typography: {
    fontFamily: 'Roboto'
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        slotProps: {
          inputLabel: {
            shrink: true
          }
        }
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px',
            padding: '0px 12px',
            height: '44px',
            border: 'none'
          },
          '& .MuiOutlinedInput-input': {
            color: '#062D29',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '150%',
            letterSpacing: '0.15px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #BDBDBD'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#9E9E9E'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#9E9E9E'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '999px',
          padding: '7px 16px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20.02px',
          letterSpacing: '0.15px',
          textTransform: 'none',
          boxShadow: 'None',
          height: '34px',
          '&.MuiButton-containedWetGreen': {
            backgroundColor: '#062D29',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#062D29',
              boxShadow: 'None'
            }
          },
          '&.MuiButton-outlinedWetGreen': {
            backgroundColor: 'white',
            color: '#062D29',
            borderColor: '#062D29'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          margin: 0
        },
        label: {
          padding: 0
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'None'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            paddingBottom: 0
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#062D29'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse'
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #D4DAE1',
          borderTop: '1px solid #D4DAE1'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #D4DAE1',
          borderLeft: '1px solid #D4DAE1',
          borderBottom: 'none'
        }
      }
    }
  }
});
export default theme;
