import { SearchIcon } from 'assets';
import { debounce } from 'lodash';

import InputAdornment from 'components/InputAdornment';
import TextField, { TextFieldProps } from 'components/TextField';

type SearchInputProps = TextFieldProps & {
  onSearch: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const SearchInput = ({ onSearch, ...props }: SearchInputProps) => {
  const onDebouncedSearchChange = debounce(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onSearch(event);
    },
    500
  );

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onDebouncedSearchChange(event);
  };

  return (
    <TextField
      slotProps={{
        input: {
          classes: {
            root: 'w-[266px] !h-[32px] !px-[14px] !py-2'
          },
          sx: {
            input: {
              fontSize: '14px!important'
            }
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }
      }}
      onChange={onChange}
      {...props}
    />
  );
};

export default SearchInput;
