import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;

const paletteColors = {
  wetGreen: augmentColor({ color: { main: '#062D29' } }),
  wetSoil: augmentColor({ color: { main: '#281A1A' } }),
  freshGreen: augmentColor({ color: { main: '#B3CBBD' } }),
  sunnyGreen: augmentColor({ color: { main: '#C3D6B9' } }),
  drySoil: augmentColor({ color: { main: '#F1EFE6' } }),
  white: augmentColor({ color: { main: '#FFFFFF' } })
};

export default paletteColors;
