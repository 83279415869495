import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_ENV_NAME,
    tracePropagationTargets: [import.meta.env.VITE_SENTRY_DSN],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

export const setSentryUser = ({ email }: { email: string }) => {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.setUser({ email });
  } else {
    console.info('Sentry DSN not provided');
  }
};
