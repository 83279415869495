import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { getToken } from 'utils/storage';

import { URLS } from 'config/urls';

const AnonimousUserLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();

    if (token) {
      navigate(URLS.DASHBOARD);
    }
  }, [navigate]);

  return <Outlet />;
};
export default AnonimousUserLayout;
