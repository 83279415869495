import { ReactNode } from 'react';

import Text from 'components/Text';

interface PageTitleProps {
  text?: string;
  icon?: ReactNode;
}

const PageTitle = ({ text, icon }: PageTitleProps) => (
  <div className="flex items-center gap-6 px-3">
    {icon}
    <Text
      classes={{
        root: 'text-xl font-medium text-[#062d29] leading-8 tracking-[0.15px]'
      }}
    >
      {text}
    </Text>
  </div>
);

export default PageTitle;
