import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import OutgrowerFarmAndContracts from 'entities/Outgrower/components/OutgrowerFarmAndContracts';
import OutgrowerProfileCard from 'entities/Outgrower/components/OutgrowerProfileCard';
import OutgrowerProfileStatistics from 'entities/Outgrower/components/OutgrowerProfileStatistics';
import { useOutgrowerDetail } from 'entities/Outgrower/sdk';

import ContentLoading from 'components/ContentLoading';
import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerProfile = () => {
  const { setPageTitle } = useLayout();
  const { id: outgrowerId } = useParams();

  useEffect(() => {
    setPageTitle(<PageTitle text="Profile"></PageTitle>);

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const { data: outgrower, isLoading: outgrowerLoading } = useOutgrowerDetail({
    outgrowerId
  });

  return (
    <div className="flex h-full flex-col gap-5 p-5">
      {outgrowerLoading && <ContentLoading />}

      {outgrower && !outgrowerLoading && (
        <>
          <OutgrowerProfileStatistics outgrower={outgrower} />
          <div className="flex gap-6">
            <OutgrowerProfileCard outgrower={outgrower} />
            <OutgrowerFarmAndContracts outgrower={outgrower} />
          </div>
        </>
      )}
    </div>
  );
};

export default OutgrowerProfile;
