import { ReactNode } from 'react';

import {
  AttachMoneyInGreenIcon,
  CheckInGreenCircleIcon,
  DoneAllInGreenIcon,
  GrassInGreenIcon,
  SubjectInGreenIcon,
  TrendingUpInGreenIcon
} from 'assets';

import { DashboardStatisticsResponse } from 'entities/Dashboard/sdk';

import Chip from 'components/Chip';
import StatisticsCard from 'components/StatisticsCard';
import Text from 'components/Text';

interface DashboardStatisticsProps {
  statistics: DashboardStatisticsResponse;
}

interface DashboardStatisticsValueProps {
  value: number;
  unit?: string;
  chip?: ReactNode;
}

const DashboardStatisticsValue = ({
  value,
  unit,
  chip
}: DashboardStatisticsValueProps) => {
  return (
    <div className="flex items-end items-baseline gap-1">
      <Text
        classes={{
          root: 'font-semibold text-[34px] text-wet-green  leading-[34px] tracking-[0.15px]'
        }}
      >
        {value}
      </Text>
      {unit && (
        <Text
          classes={{
            root: 'font-semibold text-[#757575] text-2xl leading-[34px] tracking-[0.15px]'
          }}
        >
          {unit}
        </Text>
      )}
      {chip}
    </div>
  );
};

const DashboardStatistics = ({ statistics }: DashboardStatisticsProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-3">
      <StatisticsCard
        icon={<SubjectInGreenIcon />}
        title="Active Contracts"
        value={<DashboardStatisticsValue value={statistics.active_contracts} />}
      />
      <StatisticsCard
        icon={<CheckInGreenCircleIcon />}
        title="Received Тask"
        value={
          <DashboardStatisticsValue
            value={statistics.received_tasks}
            chip={
              <Chip
                label="Received"
                variant="outlined"
                classes={{
                  root: 'font-medium h-6 text-xs py-1 px-[10px] border border-[#BBDEFB] text-[#0B79D0]'
                }}
              />
            }
          />
        }
      />
      <StatisticsCard
        icon={<DoneAllInGreenIcon />}
        title="Understood Тask"
        value={
          <DashboardStatisticsValue
            value={statistics.understood_tasks}
            chip={
              <Chip
                label="Understood"
                variant="outlined"
                classes={{
                  root: 'font-medium h-6 text-xs py-1 px-[10px] border border-[#C8E6C9] text-[#3B873E]'
                }}
              />
            }
          />
        }
      />
      <StatisticsCard
        icon={<GrassInGreenIcon />}
        title="Expected Harvest"
        value={
          <DashboardStatisticsValue
            value={statistics.expected_harvest}
            unit="t"
          />
        }
      />
      <StatisticsCard
        icon={<AttachMoneyInGreenIcon />}
        title="Financial Exposure"
        value={
          <DashboardStatisticsValue
            value={statistics.financial_exposure}
            unit="USD"
          />
        }
      />
      <StatisticsCard
        icon={<TrendingUpInGreenIcon />}
        title="Expected Farmgate Price"
        value={
          <DashboardStatisticsValue
            value={statistics.expected_farmgate_price}
            unit="USD"
          />
        }
      />
    </div>
  );
};

export default DashboardStatistics;
