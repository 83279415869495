import { useEffect, useRef, useState } from 'react';

const HEIGHT_OF_ELEMENT = 8;
const GAP_BETWEEN_ELEMENTS = 6;

const PADDING_TOP = 5;
const PADDING_BOTTOM = 5;

/*
  A loading component that will take the height of the parent container and
  calculate the number of "Skeleton" elements that can fit in the parent container. 
*/

const ContentLoading = () => {
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    setHeight(ref.current.clientHeight);
  }, [ref]);

  const numberOfElements = Math.floor(
    height /
      (HEIGHT_OF_ELEMENT * 4 +
        GAP_BETWEEN_ELEMENTS * 4 +
        PADDING_TOP * 4 +
        PADDING_BOTTOM * 4)
  );

  return (
    <div className="flex h-full animate-pulse flex-col gap-6" ref={ref}>
      {[...Array(numberOfElements)].map(() => (
        <>
          <div className={`h-${HEIGHT_OF_ELEMENT} rounded bg-white`}></div>
          <div
            className={`h-${HEIGHT_OF_ELEMENT} rounded bg-[#b3cbbd]/10`}
          ></div>
        </>
      ))}
    </div>
  );
};

export default ContentLoading;
