import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { getToken } from 'utils/storage';

import { URLS } from 'config/urls';

const AuthGuard = () => {
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    if (!token) {
      navigate(URLS.LOGIN);
    }
  }, [navigate, token]);

  return <Outlet />;
};

export default AuthGuard;
