import { ContractsListFiltersProps as ContractsFilterProps } from 'entities/Contracts/sdk';

import { SearchInput } from 'components/Filters';

interface ContractsListFiltersProps {
  filters: ContractsFilterProps;
  onFilter: (filters: ContractsFilterProps) => void;
}

const ContractsListFilters = ({
  onFilter,
  filters
}: ContractsListFiltersProps) => {
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilter({ status: filters.status, search: value });
  };

  return (
    <div className="flex justify-end">
      <SearchInput placeholder="Search your contracts" onSearch={onSearch} />
    </div>
  );
};

export default ContractsListFilters;
