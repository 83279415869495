import { FormControl, FormControlLabel } from 'components/Form';
import { Radio, RadioGroup, RadioGroupProps } from 'components/Radio';

import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions
} from 'utils/forms';

interface FormRadioFieldProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, any>;
  rules?: RegisterOptions<T>;
  options: Array<{ label: string; value: string | boolean }>;
  radioGroupProps?: RadioGroupProps & {
    className?: string;
    onChange?: (value: string | boolean) => void;
  };
  className?: string;
}

const FormRadioField = <T extends FieldValues>({
  name,
  control,
  rules,
  options,
  radioGroupProps,
  className
}: FormRadioFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl className={className}>
          <RadioGroup
            {...radioGroupProps}
            value={field.value}
            onChange={(event) => {
              const value = event.target.value;
              /*
              The default behavior of the radio button is having values as a string
              If we pass true/false booleans as values, we receive them like strings "true"/"false" on change.
              That's why we need these transformations here.
              */

              if (value === 'true') {
                field.onChange(true);
                if (radioGroupProps && radioGroupProps.onChange) {
                  radioGroupProps.onChange(true);
                }

                return;
              }

              if (value === 'false') {
                field.onChange(false);
                if (radioGroupProps && radioGroupProps.onChange) {
                  radioGroupProps.onChange(false);
                }

                return;
              }

              if (radioGroupProps && radioGroupProps.onChange) {
                radioGroupProps.onChange(value);
              }
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default FormRadioField;
