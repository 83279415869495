export const SOLID_TYPE_OPTIONS = [
  { label: 'Sand', value: 'sand' },
  { label: 'Loamy sand', value: 'loamy_sand' },
  { label: 'Sandy loam', value: 'sandy_loam' },
  { label: 'Loam', value: 'loam' },
  { label: 'Silt loam', value: 'silt_loam' },
  { label: 'Silt', value: 'silt' },
  { label: 'Sandy clay loam', value: 'sandy_clay_loam' },
  { label: 'Clay loam', value: 'clay_loam' },
  { label: 'Silty clay loam', value: 'silty_clay_loam' },
  { label: 'Sandy clay', value: 'sandy_clay' },
  { label: 'Silty clay', value: 'silty_clay' },
  { label: 'Clay', value: 'clay' }
];
