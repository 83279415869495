import ActiveContractsListTable from 'entities/Contracts/components/ContractsListTable/ActiveContractsListTable';
import ClosedContractsListTable from 'entities/Contracts/components/ContractsListTable/ClosedContractsListTable';
import { CONTRACT_STATUSES } from 'entities/Contracts/constants';
import { ContractListResponse } from 'entities/Contracts/sdk';
import { ContractsListFiltersProps } from 'entities/Contracts/sdk';

import { ListSortProps } from 'utils/sdk';

interface ContractsListTableProps {
  contracts: Array<ContractListResponse>;
  filters: ContractsListFiltersProps;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
}

const ContractsListTable = ({
  contracts,
  filters,
  onSort,
  sort
}: ContractsListTableProps) => {
  const contractsFilteredByActive = filters.status === CONTRACT_STATUSES.ACTIVE;
  const contractsFilteredByClosed = filters.status === CONTRACT_STATUSES.CLOSED;

  if (contractsFilteredByActive) {
    return (
      <ActiveContractsListTable
        contracts={contracts}
        onSort={onSort}
        sort={sort}
      />
    );
  }

  if (contractsFilteredByClosed) {
    return (
      <ClosedContractsListTable
        contracts={contracts}
        onSort={onSort}
        sort={sort}
      />
    );
  }

  return <div />;
};

export default ContractsListTable;
