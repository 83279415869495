import { useState } from 'react';

import { XIcon, XInRedCircleIcon } from 'assets';

import Alert from 'components/Alert';
import IconButton from 'components/IconButton';
import Snackbar from 'components/Snackbar';

interface ErrorProps {
  children: React.ReactNode;
}

const Error = ({ children }: ErrorProps) => {
  const [isShown, setIsShown] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isShown}
      autoHideDuration={6000}
      onClose={() => setIsShown(false)}
    >
      <Alert
        variant="outlined"
        severity="error"
        icon={<XInRedCircleIcon />}
        classes={{
          root: 'py-3 px-[10px]  gap-1 flex items-center border-solid border border-[#F44336] rounded-2xl	shadow-[0_2px_15px_1px_#0000001c] bg-white text-[#F44336] text-sm	font-medium	leading-5	tracking-[0.15px]',
          message: 'p-0',
          icon: 'px-2 py-0 m-0'
        }}
      >
        {children}
        <IconButton
          classes={{ root: 'px-0 pl-1 py-0' }}
          onClick={() => setIsShown(false)}
        >
          <XIcon />
        </IconButton>
      </Alert>
    </Snackbar>
  );
};

export default Error;
