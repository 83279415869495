import { useCallback, useState } from 'react';

import {
  CalendarIcon,
  CropIcon,
  CropVarietyIcon,
  InformationIcon,
  LandSizeIcon
} from 'assets';
import { isNil } from 'lodash';

import { FormProps } from 'entities/Contracts/components/ContractCreateForm';
import {
  CropChoicesListResponse,
  CropVarietyChoicesListResponse,
  getCropVarietyChoicesList
} from 'entities/Crops/sdk';
import { OutgrowerFarmResponse } from 'entities/Outgrower/sdk';

import { FormDateField, FormSelectField, FormTextField } from 'components/Form';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

import { Control, FieldErrors, UseFormSetValue } from 'utils/forms';

import ConractCropSelect from '../ContractCropSelect';

interface ContractFormProps {
  crops: Array<CropChoicesListResponse>;
  control: Control<FormProps>;
  setValue: UseFormSetValue<FormProps>;
  errors: FieldErrors<FormProps>;
  farm: OutgrowerFarmResponse;
}

const ContractForm = ({
  crops,
  control,
  setValue,
  errors,
  farm
}: ContractFormProps) => {
  const [cropVarieties, setCropVarieties] = useState<
    Array<CropVarietyChoicesListResponse>
  >([]);
  const [showVarieties, setShowVarieties] = useState<boolean>(false);

  const handleCropClick = useCallback(async (cropId: number | null) => {
    const varieties = cropId ? await getCropVarietyChoicesList(cropId) : [];
    setCropVarieties(varieties);
    setShowVarieties(!isNil(cropId));
  }, []);

  console.log(errors);
  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <CropIcon />
          <Text className="text-base font-semibold leading-[19.2px]">Crop</Text>
        </div>

        <Text
          color="wet-green"
          className="text-base font-normal leading-[19.2px]"
        >
          Please select the crop for the contarct. Only one crop per contract is
          allowed.
        </Text>
        <ConractCropSelect
          crops={crops}
          control={control}
          setValue={setValue}
          errors={errors}
          onCropClick={handleCropClick}
          className="border-0 p-0 shadow-none"
        />

        {showVarieties && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <CropVarietyIcon />
              <Text className="text-base font-semibold leading-[19.2px]">
                Crop variety
              </Text>
            </div>
            <FormSelectField
              name="crop_variety"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                id: 'crop_variety',
                className: 'w-[244px]',
                label: 'Crop Variety*',
                select: true,
                defaultValue: 'default-variety',
                error: !!errors.crop_variety,
                helperText: errors.crop_variety?.message
              }}
            >
              <MenuItem value="default-variety" disabled>
                Select variety
              </MenuItem>
              {cropVarieties.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </FormSelectField>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <CalendarIcon />
          <Text className="text-base font-semibold leading-[19.2px]">
            Contract duration
          </Text>
          <Tooltip
            title={
              <div>
                · Execution start: date when preplanting activities will start
                and the first weekly tasks will be send out <br />· End date:
                anticipated harvest completion
              </div>
            }
          >
            <InformationIcon />
          </Tooltip>
        </div>
        <div className="flex gap-4">
          <FormDateField
            name="signature_date"
            control={control}
            rules={{ required: 'This field is required' }}
            datePickerProps={{
              label: 'Signature date'
            }}
            fieldProps={{
              placeholder: 'Select signature date',
              error: !!errors.signature_date,
              helperText: errors.signature_date?.message
            }}
          />
          <FormDateField
            name="execution_date"
            control={control}
            rules={{ required: 'This field is required' }}
            datePickerProps={{
              label: 'Execution date'
            }}
            fieldProps={{
              placeholder: 'Select execution date',
              error: !!errors.execution_date,
              helperText: errors.execution_date?.message
            }}
          />
          <FormDateField
            name="end_date"
            control={control}
            rules={{ required: 'This field is required' }}
            datePickerProps={{
              label: 'End date'
            }}
            fieldProps={{
              placeholder: 'Select end date',
              error: !!errors.end_date,
              helperText: errors.end_date?.message
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <LandSizeIcon />
          <Text className="text-base font-semibold leading-[19.2px]">
            Land size
          </Text>
        </div>
        <div className="flex items-center gap-4">
          <FormTextField
            name="contract_land_size"
            control={control}
            rules={{
              required: 'This field is required',
              max: {
                value: farm.size,
                message: 'Contract land size cannot be larger than farm size'
              }
            }}
            fieldProps={{
              type: 'number',
              label: 'Contract land size (ha)',
              placeholder: 'Type land size',
              className: 'w-[244px]',
              error: !!errors.contract_land_size,
              helperText: errors.contract_land_size?.message
            }}
          />
          <div className="flex gap-1 text-sm text-wet-green">
            <div>Farm size is</div>
            <div className="font-medium"> {farm.size} ha</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractForm;
