import { useEffect, useState } from 'react';

import { BackArrowIcon } from 'assets';

import OutgrowerProfileCreateDialog from 'entities/Outgrower/components/ProfileCreateDialog';
import OutgrowerProfileCreateForm from 'entities/Outgrower/components/ProfileCreateForm';
import {
  OutgrowerCreateProps,
  OutgrowerCreateResponse,
  outgrowerCreate
} from 'entities/Outgrower/sdk';

import { useLayout } from 'components/Layout/hooks';
import PageTitle from 'components/PageTitle';

const OutgrowerCreatePage = () => {
  const { setPageTitle } = useLayout();
  const [createProfileDialogOpened, setCreateProfileDialogOpened] =
    useState(false);

  const [outgrower, setOutgrower] = useState<OutgrowerCreateResponse>();

  useEffect(() => {
    setPageTitle(
      <PageTitle text="Create OG Profile" icon={<BackArrowIcon />}></PageTitle>
    );

    return () => setPageTitle(null);
  }, [setPageTitle]);

  const handleSave = (data: OutgrowerCreateProps) => {
    return outgrowerCreate(data).then(
      (submitResponse: OutgrowerCreateResponse) => {
        setOutgrower(submitResponse);
        setCreateProfileDialogOpened(true);
      }
    );
  };

  return (
    <div className="h-full">
      <OutgrowerProfileCreateForm onSave={handleSave} />
      {createProfileDialogOpened && outgrower && (
        <OutgrowerProfileCreateDialog
          onClose={() => setCreateProfileDialogOpened(false)}
          outgrower={outgrower}
        />
      )}
    </div>
  );
};

export default OutgrowerCreatePage;
