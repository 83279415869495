import { XIcon } from 'assets';

import { ContractListResponse } from 'entities/Contracts/sdk';

import {
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableBodyCell,
  TableContainer,
  TableEmptyState,
  TableHead,
  TableHeaderCell,
  TableRow
} from 'components/Table';

import { ListSortProps } from 'utils/sdk';

interface ActiveContractsListTableProps {
  contracts: Array<ContractListResponse>;
  onSort: (sort: ListSortProps) => void;
  sort: ListSortProps;
}

const ActiveContractsListTable = ({
  contracts,
  onSort,
  sort
}: ActiveContractsListTableProps) => {
  const tableIsEmpty = contracts.length === 0;

  return (
    <TableContainer
      className="h-full"
      classes={{ root: 'border-[#c5c5c54d] border-b-0' }}
    >
      <Table
        stickyHeader
        classes={{
          root: `border-solid border-b border-[#c5c5c54d] border-x-0 border-t-0 ${tableIsEmpty && 'h-full'} `
        }}
      >
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              content="Contract ID"
              onSort={onSort}
              sortBy={sort}
              sortName={'id'}
              className="w-[107px]"
            />
            <TableHeaderCell content="Farmer" className="min-w-[150px]" />
            <TableHeaderCell content="Crop" className="min-w-[150px]" />
            <SortableTableHeaderCell
              content="Start Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'execution_start_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest Date"
              onSort={onSort}
              sortBy={sort}
              sortName={'end_date'}
            />
            <SortableTableHeaderCell
              content="Expected Harvest"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_harvest_amount'}
            />
            <SortableTableHeaderCell
              content="Expected  Farmgate Price"
              onSort={onSort}
              sortBy={sort}
              sortName={'expected_farmgate_price'}
            />
            <TableHeaderCell content="Contract Action" />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableIsEmpty && (
            <TableEmptyState tableColumns={9} text={'No contracts available'} />
          )}
          {contracts.map((contract) => (
            <TableRow
              key={contract.id}
              classes={{
                root: 'odd:bg-white even:bg-[#b3cbbd]/10'
              }}
            >
              <TableBodyCell content={contract.id} className="w-[107px]" />
              <TableBodyCell
                content={`${contract.outgrower_first_name} ${contract.outgrower_last_name}`}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell
                content={contract.crop}
                className="min-w-[150px] font-medium"
              />
              <TableBodyCell content={contract.execution_start_date} />
              <TableBodyCell content={contract.end_date} />
              <TableBodyCell
                content={`${contract.expected_harvest_amount} t`}
              />
              <TableBodyCell
                content={`${contract.expected_farmgate_price} t`}
              />
              <TableBodyCell
                content={<XIcon className="mx-1.5 cursor-not-allowed" />}
                className="w-[150px]"
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveContractsListTable;
