import { TractorIcon } from 'assets';

import { FormProps } from 'entities/Outgrower/components/ProfileCreateForm';
import { SOLID_TYPE_OPTIONS } from 'entities/Outgrower/constants';

import { FormSelectField, FormTextField } from 'components/Form';
import MenuItem from 'components/MenuItem';
import Text from 'components/Text';

import { Control, FieldErrors } from 'utils/forms';

interface FarmInformationProps {
  control: Control<FormProps>;
  errors: FieldErrors<FormProps>;
}

const IRRIGATION_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

const LAND_TYPE_OPTIONS = [
  { label: 'Leased', value: 'leased' },
  { label: 'Owned', value: 'owned' }
];

const FarmInformation = ({ control, errors }: FarmInformationProps) => {
  return (
    <div className="flex flex-1 flex-col gap-3">
      <Text
        classes={{
          root: 'text-xl font-semibold  text-wet-green leading-8 tracking-[0.15px]'
        }}
      >
        Farm Information
      </Text>
      <div className="flex flex-col gap-6 rounded-lg border border-solid border-[#E0E0E0] px-5 py-4">
        <div className="flex items-center gap-2">
          <TractorIcon />
          <Text className="font-base font-semibold leading-[19.2px]">Farm</Text>
        </div>
        <div className="flex gap-3">
          <div className="flex w-1/2 flex-col gap-4">
            <FormTextField
              name="farm_name"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 11
                  }
                },
                label: 'Farm name*',
                placeholder: 'Type Farm name',
                error: !!errors.farm_name,
                helperText: errors.farm_name?.message
              }}
            />
            <FormSelectField
              name="farm_land_type"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 13
                  }
                },
                id: 'farm_land_type',
                label: 'Land type*',
                select: true,
                defaultValue: 'default_land_type',
                error: !!errors.farm_land_type,
                helperText: errors.farm_land_type?.message
              }}
            >
              <MenuItem value="default_land_type" disabled>
                <div className="text-[#9E9E9E]">Select Land type</div>
              </MenuItem>
              {LAND_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>

            <FormSelectField
              name="farm_irrigation"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 15
                  }
                },
                id: 'farm_irrigation',
                label: 'Irrigation*',
                select: true,
                defaultValue: 'default-irrigation',
                error: !!errors.farm_irrigation,
                helperText: errors.farm_irrigation?.message
              }}
            >
              <MenuItem value="default-irrigation" disabled>
                <div className="text-[#9E9E9E]">Select irrigation</div>
              </MenuItem>
              {IRRIGATION_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>

            <FormTextField
              name="farm_region"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 17
                  }
                },
                label: 'Region/ State / Province (if applicable)',
                placeholder: 'Type Region/ State / Province',
                error: !!errors.farm_region,
                helperText: errors.farm_region?.message
              }}
            />

            <FormTextField
              name="farm_street"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 19
                  }
                },
                label: 'Street',
                placeholder: 'Type Street',
                error: !!errors.farm_street,
                helperText: errors.farm_street?.message
              }}
            />
          </div>
          <div className="flex w-1/2 flex-col gap-4">
            <FormTextField
              name="farm_size"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 12
                  }
                },
                type: 'number',
                label: 'Farm size (ha)*',
                placeholder: 'Type Farm size...',
                error: !!errors.farm_size,
                helperText: errors.farm_size?.message
              }}
            />
            <FormSelectField
              name="farm_soil_type"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 14
                  }
                },
                id: 'farm_soil_type"',
                label: 'Solid type*',
                select: true,
                defaultValue: 'default_soil_type',
                error: !!errors.farm_soil_type,
                helperText: errors.farm_soil_type?.message
              }}
            >
              <MenuItem value="default_soil_type" disabled>
                <div className="text-[#9E9E9E]">Select Solid type</div>
              </MenuItem>
              {SOLID_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormSelectField>
            <FormTextField
              name="farm_country"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 16
                  }
                },
                label: 'Country*',
                placeholder: 'Type Country',
                error: !!errors.farm_country,
                helperText: errors.farm_country?.message
              }}
            />
            <FormTextField
              name="farm_town"
              control={control}
              rules={{ required: 'This field is required' }}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 18
                  }
                },
                label: 'Town*',
                placeholder: 'Type Town',
                error: !!errors.farm_town,
                helperText: errors.farm_town?.message
              }}
            />
            <FormTextField
              name="farm_street_number"
              control={control}
              fieldProps={{
                slotProps: {
                  htmlInput: {
                    tabIndex: 20
                  }
                },
                type: 'number',
                label: 'Street number',
                placeholder: 'Type Street number',
                error: !!errors.farm_street_number,
                helperText: errors.farm_street_number?.message
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmInformation;
