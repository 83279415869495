const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}`;

export enum URLS {
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  OUTGROWERS = '/outgrowers',
  OUTGROWER_CREATE = '/outgrowers/create',
  OUTGROWER_PROFILE = '/outgrowers/:id/profile',
  OUTGROWER_CONTRACTS = '/outgrowers/:id/contracts',
  OUTGROWER_CONTRACT_CREATE = '/outgrowers/:id/contracts/create',
  OUTGROWER_TASKS = '/outgrowers/:id/tasks',
  OUTGROWER_TASKS_GALLERY = '/outgrowers/:id/tasks/gallery',
  CONTRACTS = '/contracts'
}
