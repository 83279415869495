import { default as MUITooltip, TooltipProps } from '@mui/material/Tooltip';

import IconButton from 'components/IconButton';

const Tooltip = (props: TooltipProps) => {
  return (
    <MUITooltip
      {...props}
      arrow
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: '#062D29',
            padding: '8px',
            borderRadius: '4px',
            fontWeight: 500
          }
        },
        arrow: {
          sx: {
            color: '#062D29'
          }
        }
      }}
    >
      <IconButton classes={{ root: 'p-0' }}>{props.children}</IconButton>
    </MUITooltip>
  );
};

export default Tooltip;
