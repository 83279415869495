import { ErrorIcon } from 'assets';

import { TableCell, TableRow } from 'components/Table';
import Text from 'components/Text';

interface TableEmptyStateProps {
  text?: string;
  tableColumns: number;
}

const TableEmptyState = ({ text, tableColumns }: TableEmptyStateProps) => {
  return (
    <TableRow className="h-full">
      <TableCell
        rowSpan={5}
        colSpan={tableColumns}
        align="center"
        className="h-full"
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <ErrorIcon />
          <Text
            color="wet-green"
            className="text-xl font-semibold leading-[30px] tracking-[0.15px]"
          >
            {text}
          </Text>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default TableEmptyState;
